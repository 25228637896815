import styled from '@emotion/styled';
import React from 'react';
import Navigation, { NavLink } from '../Navigation';
import { useTheme } from '../ThemeContext';

function Header({ name }: HeaderProps) {
  const { colorMode } = useTheme();

  const Wrapper = styled.div`
    background-color: var(--color-background);
    border-bottom: 1px solid ${colorMode === 'dark' ? 'white' : 'black'};
    padding: 1rem 1.5rem;
    display: grid;
    grid-template-columns: max-content 1fr;
    justify-items: end;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    width: calc(100% - 3rem);
    max-width: 37rem;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    @media (min-width: 768px) {
      position: static;
      padding: 1rem 0;
    }
  `;

  return (
    <Wrapper>
      <NavLink to="/">
        <LogoWrapper>
          <SiteName>{name}</SiteName>
        </LogoWrapper>
      </NavLink>
      <Navigation />
    </Wrapper>
  );
}

interface HeaderProps {
  name: string;
}

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const SiteName = styled.div`
  margin-left: 0;
`;

export default Header;
